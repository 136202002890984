<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.3125 0.625C0.794733 0.625 0.375 1.04473 0.375 1.5625V4.9375C0.375 5.45527 0.794733 5.875 1.3125 5.875H4.6875C5.20527 5.875 5.625 5.45527 5.625 4.9375V1.5625C5.625 1.04473 5.20527 0.625 4.6875 0.625H1.3125ZM1.5 4.75V1.75H4.5V4.75H1.5Z"
      fill="#0D3A93"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.3125 8.125C0.794733 8.125 0.375 8.54473 0.375 9.0625V12.4375C0.375 12.9553 0.794733 13.375 1.3125 13.375H4.6875C5.20527 13.375 5.625 12.9553 5.625 12.4375V9.0625C5.625 8.54473 5.20527 8.125 4.6875 8.125H1.3125ZM1.5 12.25V9.25H4.5V12.25H1.5Z"
      fill="#0D3A93"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.87463 9.0625C7.87463 8.54473 8.29437 8.125 8.81213 8.125H12.1871C12.7049 8.125 13.1246 8.54473 13.1246 9.0625V12.4375C13.1246 12.9553 12.7049 13.375 12.1871 13.375H8.81213C8.29437 13.375 7.87463 12.9553 7.87463 12.4375V9.0625ZM8.99963 9.25V12.25H11.9996V9.25H8.99963Z"
      fill="#0D3A93"
    />
    <path
      d="M9.00018 1.75V4.75H12.0002L12 3.25C12 2.62868 12.5037 2.125 13.125 2.125V3.64801C13.1251 3.65282 13.1252 3.65765 13.1252 3.6625V4.9375C13.1252 5.45527 12.7055 5.875 12.1877 5.875H8.81268C8.29492 5.875 7.87518 5.45527 7.87518 4.9375V1.5625C7.87518 1.04473 8.29492 0.625 8.81268 0.625H11.6248C11.6248 1.24632 11.1211 1.75 10.4998 1.75L9.00018 1.75Z"
      fill="#0D3A93"
    />
  </svg>
</template>
