<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.52548 3.45902C5.26785 4.83536 4.92892 6.77235 5.55746 8.45345L5.63625 8.65267C5.87937 9.16754 5.70287 9.78285 5.22378 10.0906L5.16838 10.1261C4.89058 9.70947 4.62549 9.24314 4.44001 8.66887C3.75473 6.64229 4.19139 4.34569 5.69496 2.70017C8.07374 0.0968496 12.1741 0.0968491 14.5529 2.70017C16.4637 4.7914 16.6514 7.93421 15.0029 10.2379L10.2477 16.8733C9.732 16.5337 9.60243 15.8332 9.96248 15.3315L14.088 9.58331C15.4274 7.71157 15.2749 5.1581 13.7224 3.45902C11.7897 1.34386 8.45819 1.34386 6.52548 3.45902Z"
      fill="#0D3A93"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.1239 4.11991C11.5737 4.11991 12.7489 5.29513 12.7489 6.74484C12.7489 8.19456 11.5737 9.36978 10.1239 9.36978C8.67418 9.36978 7.49893 8.19456 7.49893 6.74484C7.49893 5.29513 8.67418 4.11991 10.1239 4.11991ZM11.6239 6.74484C11.6239 5.91644 10.9524 5.24488 10.1239 5.24488C9.2955 5.24488 8.62393 5.91644 8.62393 6.74484C8.62393 7.57325 9.2955 8.24481 10.1239 8.24481C10.9524 8.24481 11.6239 7.57325 11.6239 6.74484Z"
      fill="#0D3A93"
    />
    <path
      d="M4.375 13.1254C3.75368 13.1254 3.25 12.6217 3.25 12.0004H6.3125C6.93382 12.0004 7.4375 12.5041 7.4375 13.1254H4.375Z"
      fill="#0D3A93"
    />
    <path
      d="M4.8125 14.4379C4.8125 15.0592 5.31618 15.5628 5.9375 15.5628H8C8 14.9415 7.49632 14.4379 6.875 14.4379H4.8125Z"
      fill="#0D3A93"
    />
  </svg>
</template>
